
import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image"
import './index.scss'

const AndroidPage = () => {


    const [showMask, setShowMask] = useState<boolean>(false);

    // const currentSystem = () => {
    //     if (typeof window !== `undefined`) {
    //         const u = navigator.userAgent;
    //         const browser = {//移动终端浏览器版本信息 
    //             trident: u.indexOf('Trident') > -1, //IE内核
    //             presto: u.indexOf('Presto') > -1, //opera内核
    //             webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
    //             gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
    //             mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
    //             ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
    //             android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
    //             iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
    //             iPad: u.indexOf('iPad') > -1, //是否iPad  
    //             webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
    //             weixin: u.indexOf('MicroMessenger') > -1, //是否微信 
    //             qq: u.match(/\sQQ/i) == "qq",//是否QQ
    //         };
    //         if (browser.ios || browser.iPhone || browser.iPad) {
    //             alert('暂无IOS版本')
    //         } else if (browser.weixin || browser.qq) {
    //             setShowMask(true)
    //         } else {
    //             const a = document.createElement('a');
    //             a.href = 'https://wepro.oss-cn-shenzhen.aliyuncs.com/android/xiaoke/1.21.9/xiaoke_v1.21.9_20220928_release.apk';
    //             a.click();
    //         }
    //     }else{
    //         console.log(`window：${window}`)
    //     }

    // }

    return (
        <>
            <div
                className="android-bj"
                onClick={(e) => {
                    e.stopPropagation();
                    setShowMask(false)
                }}>
                <div className="container">
                    <div className="pt-3 pr-3 pb-2 pl-3 text-center">
                        <h6 className="mt-5 pt-5 text-light">智能辅助 如影随形 安全放心</h6>
                        <div className="mt-5">
                            <button className="btn btn-light btn-lg pt-3 pr-4 pb-3 pl-4" onClick={(e) => {
                                e.stopPropagation();
                                currentSystem()
                            }} style={{ fontSize: '14px' }}>下载Android版本</button>
                        </div>
                        <div className="pt-3 text-light" style={{ fontSize: '12px' }}>请点击下面的网址即可复制到手机浏览器访问</div>
                        <div id="siteUrlBtn" data-clipboard-text="www.lidezhushou.com" className="pt-1 text-light">
                            ww.xiaokequnfa.com
                        </div>
                        <div className="pt-4">
                            <StaticImage src="../../assets/images/mobile-feature.png" alt="" />
                        </div>
                    </div>
                </div>
                <div id="mask" className="mask" style={showMask ? { display: "block" } : { display: "none" }}>
                    <StaticImage src={"http://www.lidezhushou.com/content/images/2020/01/browser-tip.png"} alt={""} />
                </div>
                {/* <div className="toast" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-body">
                    复制成功，可以去浏览器访问了哦！
                </div>
            </div> */}

            </div>
            <div className="l-content container">
                <h5 className="text-center mt-2 mb-3" style={{ color: '#aaa' }}>晓客管家能做什么</h5>
                <h3 className="section-title text-center mb-4">轻量高效的联系人管理 客户关系管理 安全放心</h3>
                <div className="feature-list pt-5 pr-5 pb-3 pl-5 mb-3">
                    <div className="row">
                        <div className="col-6 col-sm-4">
                            <div className="media mb-5">
                                <i className="h2 mr-3 iconfont icon-send icn-size"></i>
                                <div className="media-body">
                                    <h5 className="mb-1">一键群发</h5>
                                    <div className="feature-description">批量发送，完美解决每次群发一个个选择好友的问题，节省大量时间。</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="media mb-5">
                                <i className="h2 mr-3 iconfont icon-hearts icn-size"></i>
                                <div className="media-body">
                                    <h5 className="mb-1">亲密群发</h5>
                                    <div className="feature-description">自动加上好友昵称/尊称，图片、表情等统统OK，制作出属于好友的专属群发。</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="media mb-5">
                                <i className="h2 mr-3 iconfont icon-user-add icn-size"></i>
                                <div className="media-body">
                                    <h5 className="mb-1">群内加好友</h5>
                                    <div className="feature-description">自行设置验证信息，加人时间间隔，加人起始位置，真实模拟人工操作，实现快速爆粉。</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="media mb-5">
                                <i className="h2 mr-3 iconfont icon-user-group icn-size"></i>
                                <div className="media-body">
                                    <h5 className="mb-1">群发群消息</h5>
                                    <div className="feature-description">提供检测微信群聊的功能，营销活动只要复制粘贴，为您一次性完成一键群发至所有目标群聊</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="media mb-5">
                                <i className="h2 mr-3 iconfont icon-user-circle icn-size"></i>
                                <div className="media-body">
                                    <h5 className="mb-1">秒换头像</h5>
                                    <div className="feature-description">为您提供一键自动更换微信头像的功能，还可以私人定制专属头像，方便快捷。</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="media mb-5">
                                <i className="h2 mr-3 iconfont icon-card icn-size"></i>
                                <div className="media-body">
                                    <h5 className="mb-1">多消息转发</h5>
                                    <div className="feature-description">支持图片、视频、文章链接、小程序、公众号等多种消息类型，可批量发送。</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="media mb-5">
                                <i className="h2 mr-3 iconfont icon-grid icn-size"></i>
                                <div className="media-body">
                                    <h5 className="mb-1">一键九宫格</h5>
                                    <div className="feature-description">一键自动将目标图片切成九宫格的功能，让您一键拥有高颜值的朋友圈。</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="media mb-5">
                                <i className="h2 mr-3 iconfont icon-tags icn-size"></i>
                                <div className="media-body">
                                    <h5 className="mb-1">群发视频号</h5>
                                    <div className="feature-description">一键自动群发视频号到所有微信好友或群聊。</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="media mb-5">
                                <i className="h2 mr-3 iconfont icon-screen icn-size"></i>
                                <div className="media-body">
                                    <h5 className="mb-1">秒拼长图</h5>
                                    <div className="feature-description">自动快速拼接多张图片文件的功能，一键生成一张高清长图。</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="media mb-5">
                                <i className="h2 mr-3 iconfont icon-crop icn-size"></i>
                                <div className="media-body">
                                    <h5 className="mb-1">图片裁剪</h5>
                                    <div className="feature-description">一键将图片裁剪成不同比例大小，满足各种场景的应用。</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="media mb-5">
                                <i className="h2 mr-3 iconfont icon-tag icn-size"></i>
                                <div className="media-body">
                                    <h5 className="mb-1">群发小程序</h5>
                                    <div className="feature-description">一键自动群发小程序到所有微信好友或群聊。</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="media mb-5">
                                <i className="h2 mr-3 iconfont icon-search icn-size"></i>
                                <div className="media-body">
                                    <h5 className="mb-1">检测僵尸粉</h5>
                                    <div className="feature-description">智能自动检测僵尸粉，无打扰，高效快捷。</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="m-footer site-footer mt-3 pt-5 pb-2 bg" style={{ paddingTop: '0rem !important' }}>
                <div className="l-content container">
                    <div className="text-center text-muted">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <h5 className="text-light">联系我们</h5>
                                <StaticImage src="../../assets/images/wx.png" alt="" style={{ width: '8rem', marginBottom: '30px' }} />
                                {/* <p style={{ color: 'white', marginTop: '-22px', marginBottom: '12px' }}>微信客服</p> */}
                            </div>
                            <div>
                                <h5 className="text-light">服务</h5>
                                <ul className="nav flex-column nav-pills">
                                    <a className="nav-link text-secondary pl-0 hover-f" href="https://www.xiaokeguanjia.com/support/" target={'_blank'}>帮助中心</a>
                                </ul>
                            </div>
                        </div>
                        <p className="mb-0">
                            晓客管家© 2022 版权所有
                            &nbsp;&nbsp;
                            <span className="ml-2"><a className="text-secondary hover-f" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2022087659号-4</a></span>
                            &nbsp;&nbsp;
                            <span className="ml-2"><a className="text-secondary hover-f" href="mailto:xk2021_02@163.com" target="_blank">xk2021_02@163.com</a></span>
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )

}

export default AndroidPage;